import React from "react"
import { FullListenerLogo } from "../assets/FullListenerLogo"

import styled from "styled-components"

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f1f5fd;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BlueCap = styled.div`
  width: 478px;
  height: 12px;
  background: #4875e2;
  border-radius: 11px 11px 0px 0px;

  @media (max-width: 570px) {
    width: calc(100vw - 40px);
  }
`

export const Wrapper = styled.div`
  width: 480px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background: #ffffff;

  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(31, 38, 59, 0.06),
    0px 16px 32px rgba(31, 38, 59, 0.06);
  border-radius: 12px;

  @media (max-width: 570px) {
    width: calc(100vw - 40px);
  }
`

const BlueContainer = styled.div`
  border-radius: 6px;
  background: var(--Hover, #f1f5fd);
  margin-top: 40px;
  margin-bottom: 200px;

  @media (max-width: 400px) {
    margin-top: 20px;
    margin-bottom: 100px;
  }

`

const SorryText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Open Sans;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */

  margin: 48px 19px;

  @media (max-width: 400px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 24px 9.5px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`

const TextContainer = styled.div``

const WriteToUs = styled.a`
  text-decoration-line: underline;
  color: #000;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const H1Text = styled.h1`
  font-family: Open Sans;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #1f263b;
  margin-top: 24px;

  @media (max-width: 400px) {
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  @media (max-width: 320px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 12px;
  }
`

const ListenerLogoWrapper = styled.div`
  margin-top: 57px;
  padding: 0;

  @media (max-width: 400px) {
    margin-top: 28.5px;
    
  }
`

const LoginPage = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <BlueCap />

          <ListenerLogoWrapper>
            <FullListenerLogo width="180" height="70" />
          </ListenerLogoWrapper>

          <H1Text>Log in to your account</H1Text>

          <BlueContainer>
            <SorryText>
              Sorry! We are facing an issue. Please{" "}
              <TextContainer>
                {" "}
                <WriteToUs href="mailto:hi@truesparrow.com">
                  write to us
                </WriteToUs>
                &nbsp;if the problem persists.
              </TextContainer>
            </SorryText>
          </BlueContainer>
        </Wrapper>
      </Container>
    </>
  )
}
export default LoginPage
